import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { NotesEditorProvider, NotesEditorContext, useNotes, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "noteseditorprovider"
    }}>{`NotesEditorProvider`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { NotesEditorProvider } from '@mfrm/mfcl'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`the NotesEditorProvidew provied a context to persist NotesEditor between application pages.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={NotesEditorProvider} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<NotesEditorProvider\n  isNoteLoading={false}\n  customer={{ id: \'\' }}\n  onOpen={() => null}\n  onSave={() => null}\n  onDelete={() => null}\n>\n  <NotesEditorContext.Consumer>\n    {({ onNoteOpen }) => (\n      <Button btnType=\"primary\" onClick={() => onNoteOpen()} size=\"lg\">\n        Open Notes Editor\n      </Button>\n    )}\n  </NotesEditorContext.Consumer>\n</NotesEditorProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      NotesEditorProvider,
      NotesEditorContext,
      useNotes,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <NotesEditorProvider isNoteLoading={false} customer={{
        id: ''
      }} onOpen={() => null} onSave={() => null} onDelete={() => null} mdxType="NotesEditorProvider">
    <NotesEditorContext.Consumer>
      {({
            onNoteOpen
          }) => <Button btnType="primary" onClick={() => onNoteOpen()} size="lg" mdxType="Button">
          Open Notes Editor
        </Button>}
    </NotesEditorContext.Consumer>
  </NotesEditorProvider>
    </Playground>
    <h2 {...{
      "id": "profile-usage"
    }}>{`Profile Usage`}</h2>
    <Playground __position={2} __code={'<NotesEditorProvider\n  isNoteLoading={false}\n  customer={{ id: \'\', firstName: \'Carlos\' }}\n  onOpen={() => null}\n  onSave={() => null}\n  onDelete={() => null}\n  onProfileClick={() => alert(\'profile has been clicked\')}\n>\n  <NotesEditorContext.Consumer>\n    {({ onNoteOpen }) => (\n      <Button btnType=\"primary\" onClick={() => onNoteOpen()} size=\"lg\">\n        Open Notes Editor\n      </Button>\n    )}\n  </NotesEditorContext.Consumer>\n</NotesEditorProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      NotesEditorProvider,
      NotesEditorContext,
      useNotes,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <NotesEditorProvider isNoteLoading={false} customer={{
        id: '',
        firstName: 'Carlos'
      }} onOpen={() => null} onSave={() => null} onDelete={() => null} onProfileClick={() => alert('profile has been clicked')} mdxType="NotesEditorProvider">
    <NotesEditorContext.Consumer>
      {({
            onNoteOpen
          }) => <Button btnType="primary" onClick={() => onNoteOpen()} size="lg" mdxType="Button">
          Open Notes Editor
        </Button>}
    </NotesEditorContext.Consumer>
  </NotesEditorProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      